

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const RedirectOnStatus = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('STATUS'); 


    if (status === 'FullyPaid') {
      navigate('/success');
    } else {
      navigate('/'); 
    }
  }, [location, navigate]);

  return null; 
};

export default RedirectOnStatus;