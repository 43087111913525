

import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";

const Success = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];
    
  return (
    <>
      <section
        className="success d-flex justify-content-center align-items-center"
        style={{ height: "90vh" }}
      >
        <div>
          <h2>{t.successTitle}</h2>
          <p className="text-center">{t.successDesc}</p>
        </div>
      </section>
    </>
  );
};

export default Success;
