import React from "react";
const TopUp = () => {
  return (
    <>
      <section className="topup">
        <iframe
          title="Azercell"
          id="topUp"
          style={{height:"100vh"}}
          className="top-up w-100 "
          src="https://smartpay.az/payment/service/421?iframe=true&amp;lang=az"
        ></iframe>
      </section>
    </>
  );
};

export default TopUp;
