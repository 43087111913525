import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";

const Footer = () => {
  const year = new Date() ;
  const getFullYear = year.getFullYear() ;
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <section
        className="bg-dark text-white text-center"
      >
        <div className="d-flex justify-content-center align-items-start py-3">
          © {getFullYear} Copyright MyNumber - {t.copyright}
        </div>
      </section>
    </>
  );
};

export default Footer;
