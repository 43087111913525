import React, { useContext, useEffect, useState } from "react";
import banner from "../assets/image/banner.gif";
import left from "../assets/image/left.gif";
import right from "../assets/image/right.gif";
import center from "../assets/image/center.gif";
import info from "../assets/image/info.svg";
import { NumberContext } from "../content/NumberContext";
import ReactPaginate from "react-paginate";
import PuffLoader from "react-spinners/PuffLoader";
import azercell from "../assets/image/azercell.png";
import { NavLink } from "react-router-dom";
import slugify from "react-slugify";
import AOS from "aos";
import gift from "../assets/image/gift.gif";
import translations from "../data/langData";
import { LanguageContext } from "../context/LanguageContext";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination,Autoplay  } from "swiper/modules";

const MainInput = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { numbers, loading, error } = useContext(NumberContext);
  const [filtered, setFiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectMode, setSelectMode] = useState("");
  const [selectPrice, setSelectPrice] = useState("asc");
  const [value, setValue] = useState({
    prefiks: "",
    num1: "",
    num2: "",
    num3: "",
    num4: "",
    num5: "",
    num6: "",
    num7: "",
  });
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 500, // Set duration (optional)
      once: true, // Animation will happen once when scrolling (optional)
    });
  }, []);

  // Prefix değişimi
  const handlePrefixChange = (e) => {
    const { name, value } = e.target; // target'tan name ve value alınıyor
    setValue((prevValue) => ({
      ...prevValue, // önceki değerleri koruyarak
      [name]: value, // sadece değiştiren inputu güncelle
    }));
  };

  // Sayı değişimi
  const handleNumberChange = (index, value) => {
    if (value.length <= 1 && /^[0-9]$/.test(value)) {
      setValue((prevState) => ({
        ...prevState,
        [`num${index + 1}`]: value, // numX değerini güncelle
      }));
    }
  };

  // Bir input alanı dolduğunda bir sonrakine odaklanma
  const handleFocusNext = (index) => {
    if (index < 6) {
      // Sonraki input alanına odaklanmak için
      document.getElementById(`num${index + 2}`).focus();
    }
  };

  // Backspace tuşuna basıldığında bir önceki input alanına odaklanma
  const handleBackspace = (index, event) => {
    if (event.key === "Backspace") {
      const updatedValues = { ...value };

      // Eğer mevcut input alanında değer varsa, sil
      if (updatedValues[`num${index + 1}`] !== "") {
        updatedValues[`num${index + 1}`] = "";
        setValue(updatedValues);
      }

      // Eğer mevcut input alanı boşsa, bir önceki boş olmayan input'a odaklan
      setTimeout(() => {
        for (let i = index - 1; i >= 0; i--) {
          if (updatedValues[`num${i + 1}`] !== "") {
            document.getElementById(`num${i + 1}`).focus();
            break;
          }
        }
      }, 0);
    }
  };

  // Form gönderme ve API'ye veri gönderme
  const handleSubmit = async (event) => {
    event.preventDefault();
    const jsonData = JSON.stringify(value);
    fetch("https://mynumber.az/api/sea.php", {
      method: "POST", // POST isteği
      headers: {
        "Content-Type": "application/json", // JSON veri gönderdiğimizi belirtiyoruz
      },
      body: jsonData, // Veriyi body olarak gönderiyoruz
    })
      .then((response) => response.json()) // Yanıtı JSON formatında işliyoruz
      .then((data) => {
        if (data.results && data.results.length > 0) {
          setFiltered(data.results); // Veriyi kaydediyoruz
          setNotFound(false); // Veri bulunduğunda notFound'u false yapıyoruz
        } else {
          setFiltered([]); // Eğer veri yoksa, filtered'ı boş yapıyoruz
          setNotFound(true); // Veri bulunamadı durumunu aktarıyoruz
        }
      })
      .catch((err) => {
        console.log(err); // Hata durumunda hata mesajını konsola yazdırıyoruz
        setNotFound(true); // Hata durumunda da "veri bulunamadı" mesajını gösterebiliriz
      });
  };

  // console.log(data);
  // Tüm inputları temizleme
  const handleClearAll = () => {
    setValue({
      prefiks: "",
      num1: "",
      num2: "",
      num3: "",
      num4: "",
      num5: "",
      num6: "",
      num7: "",
    });
    setFiltered([]);
  };

  const ITEMS_PER_PAGE = 30;

  const offset = currentPage * ITEMS_PER_PAGE;

  const filteredData = selectMode
    ? numbers.filter((item) => item.mode === selectMode)
    : numbers;

  const sortedData = [...filteredData].sort((a, b) => {
    if (selectPrice === "asc") {
      return a.price - b.price; // Sort ascending
    } else {
      return b.price - a.price; // Sort descending
    }
  });

  // Pagination logic
  const currentData = sortedData.slice(offset, offset + ITEMS_PER_PAGE);

  const pageCount = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  // Sayfa tıklama fonksiyonu
  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
    window.scrollTo({ top: 100 });
  };
  useEffect(() => {
    setCurrentPage(0);
  }, [selectMode]);
  return (
    <section className="mainInput">
      <div className="container-fluid my-5">
        <div className="row justify-content-center">
          <div className="col-lg-2 col-md-12 col-12 ">
            <div className="selectInput">
              <select
                id="prefixSelect"
                name="prefiks"
                value={value.prefix}
                onChange={handlePrefixChange}
                className="prefix22"
                style={{ height: "100% !important" }}
              >
                <option value="">Sim</option>
                <option value={10}>010</option>
                <option value={50}>050</option>
                <option value={51}>051</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-12 ">
            <div className="inputs_list d-flex justify-content-center">
              {[...Array(7)].map((_, index) => (
                <input
                  key={index}
                  id={`num${index + 1}`}
                  name={`num${index + 1}`}
                  type="number"
                  maxLength={1} // 1 karakter kısıtlaması
                  min="0"
                  max="9"
                  value={value[`num${index + 1}`]}
                  onChange={(e) => handleNumberChange(index, e.target.value)}
                  onInput={() => handleFocusNext(index)} // Otomatik olarak bir sonrakine odaklan
                  onKeyDown={(e) => handleBackspace(index, e)} // Backspace ile önceki input'a geçiş
                  placeholder="x"
                  className={`num-input num${index + 1} mx-1 ${
                    index == 3 ? "ms-4" : ""
                  } ${index == 5 ? "ms-4" : ""}`}
                />
              ))}
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-12 ">
            <div className="buttons d-flex justify-content-end">
              <button id="send_search" type="submit" onClick={handleSubmit}>
                {t.search}
              </button>
              <button
                id="clear_all"
                onClick={handleClearAll}
                className="clear-all-btn ms-3"
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
          </div>

          {filtered &&
            filtered.length > 0 &&
            filtered.map((item) => (
              <NavLink
                to={`/order/${slugify(`${item.id}-${item.sim}`)}`}
                data-aos="fade-up"
                className="text-dark mb-3 mt-3"
                style={{ textDecoration: "none" }}
              >
                <div className="col-lg-12  col-sm-12 col-12 g-3" key={item.id}>
                  <div
                    className="numberSection d-flex justify-content-between"
                    style={{ background: "#fff" }}
                  >
                    <div className="outline">
                      <div className="d-flex justify-content-between align-items-center p-2">
                        <div>
                          <p
                            className="text-center ms-2 fs-4 text-dark "
                            style={{ margin: "0" }}
                          >
                            <img
                              src={azercell}
                              alt=""
                              className="img-fluid"
                              style={{ width: "30px" }}
                            />{" "}
                            +994 {item.sim}
                          </p>
                        </div>
                        <div>
                          {" "}
                          <i
                            className="fa-solid fa-square-check me-2"
                            style={{ color: "rgb(152 0 255)" }}
                          ></i>
                          <span>
                            {item.fuck == 0 ? "Fakturasız" : "Fakturalı"}
                          </span>
                        </div>
                        <div>
                          <span>
                            <i
                              className="fa-solid fa-clock me-2"
                              style={{ color: "rgb(152 0 255)" }}
                            ></i>
                            {item.dq} dq
                          </span>
                        </div>
                        <div>
                          <span>
                            <i
                              className="fa-solid fa-globe me-2"
                              style={{ color: "rgb(152 0 255)" }}
                            ></i>
                            {item.gb} gb
                          </span>
                        </div>
                      </div>
                    </div>
                    <p
                      className="text-center ms-2 fs-4 "
                      style={{ margin: "0" }}
                    >
                      <img
                        src={azercell}
                        alt=""
                        className="img-fluid"
                        style={{ width: "30px" }}
                      />{" "}
                      +994 {item.sim}
                    </p>
                    <span className="fs-4" style={{ color: "rgb(152 0 255)" }}>
                      <img
                        src={gift}
                        alt=""
                        className="img-fluid me-2"
                        style={{ width: "40px" }}
                      />
                      {item.price} ₼
                    </span>
                  </div>
                </div>
              </NavLink>
            ))}
          {notFound && (
            <div className="col-12 d-flex justify-content-center flex-column my-5">
              <div className="d-flex justify-content-center ">
                <img
                  src={info}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                  style={{ maxWidth: "100px", width: "100%" }}
                />
              </div>
              <div className="d-flex justify-content-center">
                <h5 className="text-center mt-2 w-50">{t.notResult}</h5>
              </div>
            </div>
          )}

          <div className="col-12  py-3 text-center " style={{ padding: "0" }}>
            {
              !loading && (
                <div className="row justify-content-center mx-1">
                <Swiper
                  pagination={true}
                  modules={[Pagination,Autoplay]}
                  className="mySwiper my-3"
                  slidesPerView={1} 
                  spaceBetween={10} 
                  autoplay={{
                    delay: 3000,  
                    disableOnInteraction: false,  
                  }}
                >
                  
                <SwiperSlide>
                <div className="col-lg-4 col-md-4 col-12">
                  <img
                    src={center}
                    alt=""
                    className="img-fluid"
                    style={{ borderRadius: "20px", border: "2px solid #fff" }}
                  />
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="col-lg-4 col-md-4 col-12">
                  <img
                    src={left}
                    alt=""
                    className="img-fluid"
                    style={{ borderRadius: "20px", border: "2px solid #fff" }}
                  />
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="col-lg-4 col-md-4 col-12">
                  <img
                    src={right}
                    alt=""
                    className="img-fluid"
                    style={{ borderRadius: "20px", border: "2px solid #fff" }}
                  />
                </div>
                </SwiperSlide>
                </Swiper>
                <div className="col-lg-4 col-md-4 col-12 my-4" id="gifImg">
                  <img
                    src={center}
                    alt=""
                    className="img-fluid"
                    style={{ borderRadius: "20px", border: "2px solid #fff" }}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-12 my-4" id="gifImg">
                  <img
                    src={left}
                    alt=""
                    className="img-fluid"
                    style={{ borderRadius: "20px", border: "2px solid #fff" }}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-12 my-4" id="gifImg">
                  <img
                    src={right}
                    alt=""
                    className="img-fluid"
                    style={{ borderRadius: "20px", border: "2px solid #fff" }}
                  />
                </div>
  
                
              </div>
              )
            }
            <div className="d-flex justify-content-end">
              <span className="mt-4 me-3">{t.price}</span>
              <select
                name=""
                id="selectNumber"
                className="p-2 mt-3 me-3"
                onChange={(e) => setSelectPrice(e.target.value)}
              >
                <option value="asc">{t.sort1}</option>
                <option value="desc">{t.sort2}</option>
              </select>

              <select
                name=""
                id="selectNumber"
                className="p-2 mt-3 me-3"
                onChange={(e) => setSelectMode(e.target.value)}
              >
                <option value="">{t.numbers}</option>
                <option value="1">{t.category}</option>
                <option value="2">{t.category1}</option>
                <option value="3">{t.category2}</option>
                <option value="4">{t.category3}</option>
              </select>
            </div>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <PuffLoader color="#30D3FF" size={70} />
            </div>
          ) : (
            currentData.map((item) => {
              return (
                <NavLink
                  to={`/order/${slugify(`${item.id}-${item.sim}`)}`}
                  data-aos="fade-up"
                  className="text-dark mb-3"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="col-lg-12  col-sm-12 col-12 g-3"
                    key={item.id}
                  >
                    <div
                      className="numberSection d-flex justify-content-between"
                      style={{ background: "#fff" }}
                    >
                      <div className="outline">
                        <div className="d-flex justify-content-between align-items-center p-2">
                          <div>
                            <p
                              className="text-center ms-2 fs-4 text-dark "
                              style={{ margin: "0" }}
                            >
                              <img
                                src={azercell}
                                alt=""
                                className="img-fluid"
                                style={{ width: "30px" }}
                              />{" "}
                              +994 {item.sim}
                            </p>
                          </div>
                          <div>
                            {" "}
                            <i
                              className="fa-solid fa-square-check me-2"
                              style={{ color: "rgb(152 0 255)" }}
                            ></i>
                            <span>{item.fuck == 0 ? t.fuck : "Fakturalı"}</span>
                          </div>
                          <div>
                            <span>
                              <i
                                className="fa-solid fa-clock me-2"
                                style={{ color: "rgb(152 0 255)" }}
                              ></i>
                              {item.dq} dq
                            </span>
                          </div>
                          <div>
                            <span>
                              <i
                                className="fa-solid fa-globe me-2"
                                style={{ color: "rgb(152 0 255)" }}
                              ></i>
                              {item.gb} gb
                            </span>
                          </div>
                        </div>
                      </div>
                      <p
                        className="text-center ms-2 fs-4 "
                        style={{ margin: "0" }}
                      >
                        <img
                          src={azercell}
                          alt=""
                          className="img-fluid"
                          style={{ width: "30px" }}
                        />{" "}
                        +994 {item.sim}
                      </p>
                      <span
                        className="fs-4"
                        style={{ color: "rgb(152 0 255)" }}
                      >
                        <img
                          src={gift}
                          alt=""
                          className="img-fluid me-2"
                          style={{ width: "40px" }}
                        />
                        {item.price} ₼
                      </span>
                    </div>
                  </div>
                </NavLink>
              );
            })
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center my-5 paginateContainer">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"previousLinkClassName"} // Bu xətti əlavə et
            nextClassName={"page-item"}
            nextLinkClassName={"nextLinkClassName"} // Bu xətti əlavə et
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </section>
  );
};

export default MainInput;
