import React, { useContext, useEffect, useState } from "react";
import { NumberContext } from "../content/NumberContext";
import { useParams } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import azercell from "../assets/image/azercell.png";
import { jwtDecode } from "jwt-decode";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import axios from "axios";
const Order = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { orderId } = useParams();
  const { numbers } = useContext(NumberContext);
  const splitOrder = orderId.split("-")[0];
  const findData = numbers && numbers.find((item) => item.id == splitOrder);
  const [user, setUser] = useState({});
  const [fullPayment, setFullPayment] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [change, setChange] = useState({
    id: splitOrder,
    name: "",
    serial: "",
    fin: "",
    contact: "",
    fullPayment: "",
    taksit: "",
    description:fullPayment,

  });


  const month = [
    { id: "taksit6", monthDate: 6 },
    { id: "taksit12", monthDate: 12 },
    { id: "taksit18", monthDate: 18 },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChange((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleButton = (btnId) => {
    setSelectedMonth(btnId);
    setFullPayment("");
  };

  const [radio, setRadio] = useState("online");

  const formatPhoneNumber = (number) => {
    if (number) {
      const digits = number.replace(/\D/g, "");
      if (digits.length === 9) {
        return `+994 ${digits.slice(0, 2)} ${digits.slice(2, 5)} ${digits.slice(
          5,
          7
        )} ${digits.slice(7)}`;
      }
    }
    return number;
  };

  const handleRadioChange = (e) => {
    setRadio(e.target.value);
  };
  useEffect(() => {
    const userLS = localStorage.getItem("user");
    if (userLS) {
      const decodedUser = jwtDecode(JSON.parse(userLS));
      setUser(decodedUser.data[0]);
    } else {
      setUser();
    }
  }, [localStorage.getItem("user")]);

  useEffect(() => {
    if (user) {
      setChange((prevState) => ({
        ...prevState,
        name: user.name || "",
        fin: user.fin || "",
      }));
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("nid", change.nid);
    formData.append("name", change.name);
    formData.append("serial", change.serial);
    formData.append("fin", change.fin);
    formData.append("contact", change.contact);
    axios
      .post("https://mynumber.az/api/kb.php", formData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(change);

  return (
    <>
      {findData ? (
        <section className="order py-5 mx-2">
          <h1 className="fs-3 text-start mb-4">{t.form}</h1>
          <div className="container-fluid">
            <div className="row g-2">
              <div className="col-lg-5 col-12 p-0">
                <div className="numberDetails">
                  <div className="d-flex justify-content-between  p-3">
                    <div>
                      <h6>{t.number}</h6>
                      <h5 style={{ fontWeight: "650", color: "#30d3ff" }}>
                        <img
                          src={azercell}
                          alt=""
                          className="img-fluid"
                          style={{ width: "30px" }}
                        />{" "}
                        {findData ? formatPhoneNumber(findData.sim) : ""}
                      </h5>
                    </div>
                    <h6>
                      Sim (
                      {findData.mode == 1
                        ? t.category
                        : findData.mode == 2
                        ? t.category1
                        : findData.mode == 3
                        ? t.category2
                        : t.category3}
                      )
                    </h6>
                  </div>
                  <div className="numderText ms-3 ">
                    <p>
                      <span style={{ fontWeight: "600" }}>{t.price} - </span>{" "}
                      {findData.price} ₼
                    </p>
                    <p>
                      <i
                        className="fa-solid fa-square-check me-2"
                        style={{ color: "#1ba1c6" }}
                      ></i>
                      {findData.fuck == 0 ? t.fuck : ""}
                    </p>
                    <p>
                      <i
                        className="fa-solid fa-clock me-2"
                        style={{ color: "#1ba1c6" }}
                      ></i>
                      {findData.dq} dq
                    </p>
                    <p>
                      <i
                        className="fa-solid fa-globe me-2"
                        style={{ color: "#1ba1c6" }}
                      ></i>
                      {findData.gb} gb
                    </p>
                    <p>
                      <i
                        className="fa-solid fa-location-dot me-2"
                        style={{ color: "#1ba1c6" }}
                      ></i>
                      {findData.loc}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-12 ">
                <div className="orderForm">
                  <form action="https://mynumber.az/api/kb.php" method="POST">
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="text"
                          name="id"
                          value={change.id}
                          readOnly
                          className="d-none"
                        />
                        <input
                          name="name"
                          type="text"
                          onChange={handleChange}
                          placeholder={t.name}
                          className="w-100"
                          value={user ? user.name : change.name}
                          disabled={user ? "disabled" : ""}
                          required
                        />
                      </div>
                      <div className="col-6">
                        <input
                          name="serial"
                          type="text"
                          placeholder={t.seria}
                          onChange={handleChange}
                          value={change.serial}
                          className="w-100"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <input
                          name="fin"
                          type="text"
                          placeholder={t.fin}
                          className="w-100"
                          onChange={handleChange}
                          value={user ? user.fin : change.fin}
                          disabled={user && "disabled"}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <input
                          name="contact"
                          type="text"
                          placeholder={t.phone}
                          onChange={handleChange}
                          value={change.contact}
                          className="w-100"
                          required
                        />
                      </div>
                      <div className="col-12">
                        <textarea name="" id="" placeholder={t.note}></textarea>
                      </div>

                      
                     <div className="ms-3 py-2 paymentDiv" >
                     <h5 className="my-2">{t.onlineTitle}</h5>
                      <div className="col-12 ">
                        <input
                          type="radio"
                          name="paymentMethod"
                          id="online"
                          className="radio me-2"
                          value="online"
                          style={{ width: "auto", height: "auto" }}
                          onChange={handleRadioChange}
                          checked={radio === "online"}
                        />
                        <label htmlFor="online" style={{ cursor: "pointer" }}>
                          {t.online}
                        </label>
                      </div>
                      {radio === "online" && (
                        <>
                          <div className="col-12">
                            <button
                              type="button"
                              className="fullPayment my-2"
                              onClick={() => {
                                setFullPayment("Tək çəkim");
                                setSelectedMonth("");
                              }}
                              style={{
                                background: `${
                                  fullPayment == "Tək çəkim" ? "#fff" : ""
                                }`,
                                color: `${
                                  fullPayment == "Tək çəkim" ? "#000" : ""
                                }`,
                              }}
                            >
                              {t.fullPayment}
                            </button>
                          </div>
                          <p>{t.partPayment}</p>
                          <div className="col-12">
                            <div className="onlineCredit">
                              {month.map((item) => {
                                return (
                                  <button
                                  type="button"
                                    className={`mx-2 ${
                                      language == "ru" ? "p-2" : ""
                                    }`}
                                    onClick={() => handleButton(item.id)}
                                    style={{
                                      background: `${
                                        selectedMonth == item.id ? "#fff" : ""
                                      }`,
                                      color: `${
                                        selectedMonth == item.id ? "#000" : ""
                                      }`,
                                    }}
                                  >
                                    {item.monthDate} {t.month}
                                  </button>
                                );
                              })}
                            </div>
                          </div>
                        </>
                      )}
                     </div>
                    </div>


                    <div className="col-12">
                      <div className="d-flex justify-content-end align-items-center mt-3">
                        <button
                          className="fullPayment w-50"
                          style={{ textTransform: "uppercase" }}
                          type="submit"
                        >
                          {t.orderSend}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <PuffLoader color="#30D3FF" size={70} />
        </div>
      )}
    </>
  );
};

export default Order;
