import React, { useContext, useEffect } from "react";
import about1 from "../assets/image/about1.png";
import about2 from "../assets/image/about2.png";
import about3 from "../assets/image/about3.png";
import AOS from "aos";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
const About = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1200, // Set duration (optional)
      easing: "ease-in-out", // Set easing (optional)
      once: true, // Animation will happen once when scrolling (optional)
    });
  }, []);
  return (
    <>
      <section className="about py-5">
        <h1 className="text-center my-3" data-aos="fade-in">{t.aboutTitle}</h1>
        <div className="d-flex justify-content-center align-items-center">
          <p
            className="text-center text-secondary"
            style={{ maxWidth: "500px", width: "100%", fontWeight: "normal" }}
            data-aos="fade-in"
          >
          {t.aboutDesc}
          </p>
        </div>
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 col-12" data-aos="fade-right">
              <img
                src={about1}
                alt=""
                className="img-fluid"
                
              />
            </div>
            <div className="col-lg-6 col-12 mt-5" data-aos="fade-left">
              <div
                className="d-flex justify-content-center flex-column align-items-start mt-5 p-3"
                style={{ background: "#fff", borderRadius: "20px" }}
              >
                <h2>{t.aboutTitle1}</h2>
                <p>
                 {t.aboutDesc1}
                </p>
              </div>
            </div>



            <div className="col-lg-6 col-sm-6 col-12 mt-5 " data-aos="fade-right" >
              <div
                className="d-flex justify-content-center flex-column align-items-start mt-5 p-3"
                style={{ background: "#fff", borderRadius: "20px" }}
              >
                <h2>{t.aboutTitle2}</h2>
                <p>
                {t.aboutDesc2}
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-12" data-aos="fade-left">
              <img
                src={about2}
                alt=""
                className="img-fluid"
                
              />
            </div>





            <div className="col-lg-6 col-sm-6 col-12"data-aos="fade-right">
              <img
                src={about3}
                alt=""
                className="img-fluid"
                
              />
            </div>
            <div className="col-lg-6 col-sm-6 col-12 mt-5" data-aos="fade-left">
              <div
                className="d-flex justify-content-center flex-column align-items-start mt-5 p-3 "
                style={{ background: "#fff", borderRadius: "20px" }}
              >
                <h2>{t.aboutTitle3  }</h2>
                <p>
                {t.aboutDesc3}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
