import React from 'react'
import AppRouter from './router/AppRouter'
import { LanguageProvider } from './context/LanguageContext'

const App = () => {
  return (
    <>
    <LanguageProvider>
    <AppRouter/>
    </LanguageProvider>
    </>
  )
}

export default App
