import React, { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MainInput from "../pages/MainInput";
import Test from "../components/Test";
import TopUp from "../pages/TopUp";
import left from "../assets/image/leftgif.gif"; // Assuming your left image is in the assets folder
import right from "../assets/image/rightgif.gif"; // Same for the right image
import Numbers from "../pages/Numbers";
import Order from "../pages/Order";
import About from "../pages/About";
import SeoNumbers from "../pages/SeoNumbers";
import "aos/dist/aos.css";
import { NumberContext } from "../content/NumberContext";
import Success from "../pages/Success";
import RedirectOnStatus from "../pages/Redirect";
const AppRouter = () => {
  const { numbers, loading, error } = useContext(NumberContext);
  return (
    <BrowserRouter>
      <div className="container-fluid p-0">
        <div className="row">
          <div
            className="col-12 col-md-1 p-0 leftImage"
            style={{
              flex: 2.5,
              position: "sticky",
              top: "0",
              height: `${!loading ? "100%" : "100vh"}`,
            }}
          >
            {!loading && (
              <img src={left} alt="Left" className="img-fluid" loading="lazy" />
            )}
          </div>

          <div className="col-12 col-md-10 p-0" style={{ flex: 10 }}>
            <Header />
            <Routes>
              <Route path="/" element={<MainInput />} />
              <Route path="/" element={<MainInput />} />
              <Route path="/test" element={<Test />} />
              <Route path="/top-up" element={<TopUp />} />
              <Route path="/about" element={<About />} />
              <Route path="/:number" element={<Numbers />} />
              <Route path="/order/:orderId" element={<Order />} />

              <Route path="/azercell-nomre" element={<SeoNumbers />} />
              <Route path="/azercell-nomreler" element={<SeoNumbers />} />
              <Route path="/azercell-nomre-al" element={<SeoNumbers />} />
              <Route path="/azercell-211" element={<SeoNumbers />} />
              <Route path="/onlayn-nomre-almaq" element={<SeoNumbers />} />
              <Route path="/211-nomre" element={<SeoNumbers />} />
              <Route
                path="/azercell-onlayn-nomreler"
                element={<SeoNumbers />}
              />
              <Route path="/vip-azercell-nomreler" element={<SeoNumbers />} />
              <Route path="/azercell-esim" element={<SeoNumbers />} />
              <Route path="/onlayn-esim-al" element={<SeoNumbers />} />
              <Route path="/esim-almaq" element={<SeoNumbers />} />
              <Route path="/success" element={<Success />} />
              <Route path="/redirect" element={<RedirectOnStatus />} />
            </Routes>
            <Footer />
          </div>

          <div
            className="col-12 col-md-1 p-0 leftImage"
            style={{
              flex: 2.5,
              position: "sticky",
              top: "0",
              height: `${!loading ? "100%" : "100vh"}`,
            }}
          >
            {!loading && (
              <img
                src={right}
                alt="Right"
                className="img-fluid"
                loading="lazy"
              />
            )}
          </div>
        </div>
      </div>


    </BrowserRouter>
  );
};

export default AppRouter;
