import React, { useContext } from "react";
import { NumberContext } from "../content/NumberContext";
import azercell from "../assets/image/azercell.png" ;
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
const SeoNumbers = () => {
  const { numbers } = useContext(NumberContext);
  const location = useLocation(); // Access the current URL

  // Extract the path part from the URL
  const path = location.pathname.split("/").pop();

  // Remove dashes or replace them with spaces
  const formattedPath = path.replace(/-/g, " ");
  return (
    <>
    <Helmet>
        <title>{formattedPath.toUpperCase()}</title>
    </Helmet>
      <section className="numbers pb-5">
        <h1 className="text-center my-5">{`${formattedPath.toUpperCase()}`}</h1>
        <div className="container">
          <div className="row">
            {numbers &&
              numbers.map((item) => {
                return (
                  <div className="col-lg-12  col-sm-6 col-12 g-3" key={item.id}>
                    <div
                      className="numberSection d-flex justify-content-between"
                      style={{ background: "#fff" }}
                    >
                      <p
                        className="text-center ms-2 fs-4 "
                        style={{ margin: "0" }}
                      >
                        <img
                          src={azercell}
                          alt=""
                          className="img-fluid"
                          style={{ width: "30px" }}
                        />{" "}
                        +994 {item.sim}
                      </p>
                      <span className="fs-4" style={{ color: "blue" }}>
                        {item.price} ₼
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default SeoNumbers;
