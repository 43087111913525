import { createContext,useEffect,useState } from "react";

const defaultLanguage = 'az' ;

export const LanguageContext = createContext({
    language: '' ,
    changeLanguage : ()=>{},
}) ;

export const LanguageProvider =({children}) => {
    const [language,setLanguage] = useState(defaultLanguage) ;
    useEffect(()=>{
        const savedLang = localStorage.getItem("language") ;
        if (savedLang) {
             setLanguage(savedLang) ;
        } 
    },[])

    const changeLanguage = (newLanguage)=>{
        setLanguage(newLanguage);
        localStorage.setItem("language",newLanguage) ;
    } ;

    return (
        <LanguageContext.Provider value={{language,changeLanguage}}>
            {children}
        </LanguageContext.Provider>
    ) ;
} ;